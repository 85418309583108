<template>
    <div class="main_platform" v-loading="!dataLoading">
        <div class="main_inner" v-if="!isSecond">
            <div class="Myp_box_top">
                <div class="enter_box">
                    <span>{{ $t('選擇支付方式') }}</span>
                    <el-select class="pay_mt_select1" v-model="selectValue" @change="selectChange"
                        :placeholder="$t('請選擇')">
                        <el-option v-for="item in allBankInfo" :key="item.id" :label="`${rechargeType[item.type]} --- ${item.bank_name||item.pay_name}`"
                            :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="enter_box">
                    <span>{{ $t('充值金額') }}</span>
                    <el-input v-model="depositNum" @input="onInput" type="text" class="pay_mt_select" :placeholder="$t('請輸入充值金額')"></el-input>
                </div>
                <button type="button" class="form_button" @click="submitDeposit">{{ $t('確認') }}</button>
            </div>

            <div class="Myp_box_center">
                <div class="block_R_top_t">
                    <span class="block_title">{{ $t('請選擇您的銀行賬號') }}
                        <span v-if="wtBankList.length > 0" @click="setting" class="add_card_btn">{{ $t('新增卡片') }}</span>
                    </span>
                    <span v-if="$store.state.userInfo && $store.state.windowWidth > 768">{{ $t('餘額') }} {{
                        this.$helper.get("rule").currency.code }} {{
                            numFormat($store.state.userInfo.balance)
                        }}</span>
                </div>

                <div>
                    <div class="bank_wrap">
                        <ul v-if="wtBankList.length > 0">
                            <li @click="selectWithdrawalChannels = item" v-for="item in wtBankList" :key="item.id"
                                class="xwbank_card" :class="selectWithdrawalChannels.id === item.id ? 'active' : ''">
                                <div class="item-title">
                                    <img src="@/assets/images/ban-icon.png" alt="" class="bank-icon">
                                    <span>{{ item.bank_name }}</span>
                                </div>
                                <div class="item-num">
                                    <span
                                        v-for="item, index in (item.bank_number.slice(0, 4) + '*******' + item.bank_number.slice(-4)) "
                                        :key="index">{{ item }}</span>
                                </div>
                            </li>
                        </ul>

                        <div v-else>
                            <span>{{ $t('未檢測到繫結卡片') }}</span>
                            <button @click="setting" type="button" class="set_btn"> {{ $t('去設定') }}</button>
                        </div>


                    </div>
                </div>

                <div class="num_list">
                    <div class="num_item" :class="item.num == depositNum ? 'active' : ''"
                        v-for="(item, index) in numList" :key="index" @click="tabNum(item.num)">
                        <template v-if="!checked">
                            <div class="hot-wapper" v-if="item.giftAmount">
                                + {{ item.giftAmount }}
                            </div>
                            <div class="hot-wapper" v-else-if="item.giftRatio">
                                + {{ item.giftRatio }}%
                            </div>
                            <div class="hot-wapper" v-else-if="item.isHot">HOT</div>
                        </template>
                        {{ item.num }}
                    </div>
                </div>

                <div class="checked_wrap">
                    <!-- 活動選擇框 -->
                    <div class="action-input num-item" v-if="isAction && $t('recharge_activity') != 'hide'"
                        @click="isActionList = true">
                        <div class="action-input-content">{{ activeAction.news_title }}</div>
                        <i class="el-icon el-icon-caret-right"></i>
                    </div>

                    <!-- 參與活動提示 -->
                    <el-checkbox v-model="checked" class="checkbox" v-else-if="$t('recharge_activity') != 'hide'">
                        <span :class="checked ? 'checked-ac' : 'checked'">{{
                            $t("不參與本次優惠活動")
                            }}</span>
                    </el-checkbox>
                    <div class="tips_warn" v-if="checked">{{ $t("如果您同意不參與本次優惠活動，當前該筆充值將不參與任何優惠活動。") }}</div>
                </div>


            </div>
        </div>

        <div class="main_inner" v-else>
            <div v-if="selectRechargeChannel.type == 1">
                <div class="code-wapper">
                    <div class="qr-wapper">
                        <img v-if="selectRechargeChannel.bank_qrcode" :src="selectRechargeChannel.bank_qrcode"
                            class="vue-qr" />
                        <vue-qr v-else class="vue-qr" :margin="10" :auto-color="true" :dot-scale="1"
                            :text="selectRechargeChannel.bank_number || qrcodeData.address" />
                    </div>
                    <div class="code-input">
                        <span>{{ selectRechargeChannel.bank_number || qrcodeData.address }}</span>
                        <div class="copy-icon" v-clipboard:copy="selectRechargeChannel.bank_number"
                            v-clipboard:success="onCopy">
                            <img src="@/assets/images/copy3.png" />
                        </div>
                    </div>
                    <div class="djs">{{ djs }}</div>
                </div>
            </div>

            <!-- 線下充值銀行卡資訊展示 -->
            <div class="bank-wapper">
                <template v-if="selectRechargeChannel.type == 1">
                    <div class="bank-item">
                        <div class="bank-title">
                            {{ $t("付款金額") }}
                        </div>
                        <div class="bank-content">
                            <span>{{ calc(depositNum, true) + ' ' + selectRechargeChannel.currencyApi.currency_code }}</span>
                            <div v-clipboard:copy="calc(depositNum, true) + ' ' + selectRechargeChannel.currencyApi.currency_code" v-clipboard:success="onCopy"
                                class="copy-icon-wapper">
                                <img src="@/assets/images/copy2.png" alt="" class="copy-icon4" />
                            </div>
                        </div>
                    </div>
                    <div class="bank-item">
                        <div class="bank-title">
                            {{ $t("到賬金額") }}
                        </div>
                        <div class="bank-content">
                            <span>{{ numFormat(depositNum) + ' ' + bankAll.currency_code}}</span>
                        </div>
                    </div>
                </template>
                <template v-else>

                    <div class="bank-item" v-if="selectRechargeChannel.bank_name">
                        <div class="bank-title">
                            {{
                                $t(
                                    selectRechargeChannel.currencyApi.name == "USDT"
                                        ? "鏈名稱"
                                        : "銀行名稱"
                                )
                            }}
                        </div>
                        <div class="bank-content">
                            <span>{{ selectRechargeChannel.bank_name }}</span>
                            <div v-clipboard:copy="selectRechargeChannel.bank_name" v-clipboard:success="onCopy"
                                class="copy-icon-wapper">
                                <img src="@/assets/images/copy2.png" alt="" class="copy-icon4" />
                            </div>
                        </div>
                    </div>
                    <div class="bank-item" v-if="selectRechargeChannel.bank_number">
                        <div class="bank-title">
                            {{
                                $t(
                                    selectRechargeChannel.currencyApi.name == "USDT"
                                        ? "錢包地址"
                                        : "銀行賬戶"
                                )
                            }}
                        </div>
                        <div class="bank-content">
                            <span>{{ selectRechargeChannel.bank_number }}</span>
                            <div v-clipboard:copy="selectRechargeChannel.bank_number" v-clipboard:success="onCopy"
                                class="copy-icon-wapper">
                                <img src="@/assets/images/copy2.png" alt="" class="copy-icon4" />
                            </div>
                        </div>
                    </div>
                    <div class="bank-item" v-if="selectRechargeChannel.bank_khm">
                        <div class="bank-title">{{ $t("戶名") }}</div>
                        <div class="bank-content">
                            <span>{{ selectRechargeChannel.bank_khm }}</span>
                            <div v-clipboard:copy="selectRechargeChannel.bank_khm" v-clipboard:success="onCopy"
                                class="copy-icon-wapper">
                                <img src="@/assets/images/copy2.png" alt="" class="copy-icon4" />
                            </div>
                        </div>
                    </div>
                    <div class="bank-item" v-if="selectRechargeChannel.bank_open">
                        <div class="bank-title">{{ $t("開戶行") }}</div>
                        <div class="bank-content">
                            <span>{{ selectRechargeChannel.bank_open }}</span>
                            <div v-clipboard:copy="selectRechargeChannel.bank_open" v-clipboard:success="onCopy"
                                class="copy-icon-wapper">
                                <img src="@/assets/images/copy2.png" alt="" class="copy-icon4" />
                            </div>
                        </div>
                    </div>
                    <div class="bank-item">
                        <div class="bank-title">
                            {{
                                $t("付款金額")
                            }}
                        </div>
                        <div class="bank-content">
                            <span>{{ selectRechargeChannel.currencyApi.currency_symbol }} {{ calc(depositNum, true)
                                }}</span>
                            <div v-clipboard:copy="calc(depositNum, true)" v-clipboard:success="onCopy"
                                class="copy-icon-wapper">
                                <img src="@/assets/images/copy2.png" alt="" class="copy-icon4" />
                            </div>
                        </div>
                    </div>
                    <div class="bank-item">
                        <div class="bank-title">
                            {{
                                $t("到賬金額")
                            }}
                        </div>
                        <div class="bank-content">
                            <span>{{ dw }} {{ numFormat(depositNum) }}</span>
                            <div v-clipboard:copy="calc(depositNum, true)" v-clipboard:success="onCopy"
                                class="copy-icon-wapper">
                                <img src="@/assets/images/copy2.png" alt="" class="copy-icon4" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>

            <!-- 憑證 -->
            <div v-if="
                selectRechargeChannel.pay_type == 0 ||
                selectRechargeChannel.pay_type == 3 ||
                selectRechargeChannel.pay_type == 4 ||
                selectRechargeChannel.pay_type == 6
            " class="upload_wrap">
                <span class="upload-title">{{ $t("上傳憑證") }}</span>
                <div class="upload-wapper">
                    <el-upload class="avatar-uploader" :action="rootUrl + '/upload/img'" :show-file-list="false"
                        :on-success="uploadSuccess" :headers="uploadHeader">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i class="el-icon-plus avatar-uploader-icon" v-else></i>
                    </el-upload>
                    
                    <div>請上傳轉帳完成圖，並且通知客服</div>
                </div>
            </div>

            <div class="btn_wrap">
                <el-button class="button_blue button_de" @click="isSecond = false"
                    style="background-color: #4D535B;margin-top:20px;">
                    <span class="btn-txt">{{ $t("上一步") }}</span>
                    <img class="btn-icon" src="@/assets/images/sahngyibu.png" />
                </el-button>
                <el-button class="button_blue button_de" @click="submitDeposit" style="margin:0;margin-top:10px;"
                    :loading="depositBtnLoading">
                    <span class="btn-txt">{{ $t("已完成支付") }}</span>
                    <img class="btn-icon" src="@/assets/images/wancheng.png" />
                </el-button>
            </div>

        </div>

        <div class="block_big">
            <div class=" data_L_Tips">
                <div class="icon_note"></div>   
                {{ $t('注意事項') }}
            </div>
            <div class="block_R_tips">
                <div class="data_subtw">
                    <ul class="precautions_tw" v-if="selectRechargeChannel.id">
                        <li>
                            {{ $t("充值單筆最低") }}
                            <span class="text-red">1000</span>，
                            {{ $t('最高限額') }}
                            <span class="text-red">49999</span>
                        </li>
                        <li>
                            {{ $t("充值-選擇支付方式，可點選下拉進行選擇，目前有銀行轉帳、超商代碼、USDT（等待開放）") }}
                        </li>
                        <li>
                            {{ $t("請務必依照指定的銀行帳號進行轉帳，若轉至舊有的銀行帳號將無法查收，恕不負責。") }}
                        </li>
                        <li>{{ $t("轉帳時請勿填寫任何備註，否則系統無法進行核對上分。") }}</li>
                        <li>{{ $t('請使用與實名認證相同之銀行帳號進行充值與托售，以免無法正常充值與托售，若使用不同銀行卡帳號將無法正常充值與托售造成相關狀況，將收取金額x20%手續費後退款。') }}</li>
                        <li>{{ $t('為了加快上分速度，請至交易記錄上傳交易成功的明細。') }}</li>
                        <li>{{ $t('如果您同意不參與本次優惠活動，當前該筆充值將不參與任何優惠活動。') }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <xwBankList ref="xwBankList" />
    </div>
</template>

<script>
import {
bank_my_api,
recharge_apply_api,
recharge_get_all_api
} from "@/api/user";
import xwBankList from "@/components/xwBankList.vue";
import { serviceLink } from "@/utils/serviceLink.js";
export default {
    components:{xwBankList},
    data() {
        return {
            rechargeType:['銀行卡','usdt','電子錢包','PIX電子錢包'],
            options: [],
            depositNum: '',
            // numList: [],
            selectValue: 0,
            selectRechargeChannel: {},
            uploadHeader: { usertoken: "", usertokenkey: "" },
            imageUrl: "",
            bankAll: {

            },
            allBank: [],
            allUsdt: [],
            allWallet: [],
            allPix: [],
            allBankInfo: [],
            allRechangeChannel: {},
            dataLoading: false,
            checked: false,
            isActionList: false,
            depositBtnLoading: false,
            isSecond: false,
            rootUrl: process.env.VUE_APP_API_URL,
            withdrawList: '',
            wtBankList: '',
            wtAllBank: '',
            wtAllUsdt: '',
            wtAllWallet: '',
            wtAllPix: '',
            selectWithdrawalChannels: {},
        }
    },
    computed: {
    

        isAction() {
            if (!this.dataLoading) {
                return
            }
            return this.bankAll.action_list.length && this.bankAll.action_is_force_join == 1
        },
        numList() {
            if (!this.dataLoading) {
                return
            }
            const recharge_amount_discount = this.bankAll.recharge_amount_discount;
            const {
                bank_recharge_list,
                hot_bank_recharge_list,
            } = this.bankAll.recharge_amount_sitting;
            return bank_recharge_list.map((item) => {
                const discount = recharge_amount_discount.find(
                    (discountItem) => item == discountItem.amount
                );
                let giftRatio = 0,
                    giftAmount = 0;
                if (discount) {
                    if (discount.type == 1) {
                        giftAmount = discount.bl_or_amount;
                    } else {
                        giftRatio = discount.bl_or_amount;
                    }
                }
                // 如果是首衝的話，重新計算贈送金額
                if (this.isAction) {
                    giftAmount = 0
                    giftRatio = 0
                    if (this.activeAction) {
                        const regNum = this.activeAction.reg_json.find(regItem => {
                            return parseFloat(item) <= parseFloat(regItem.max_reg) && parseFloat(item) >= parseFloat(regItem.min_reg)
                        })
                        if (regNum) {
                            if (regNum.type == 1) {
                                giftAmount = regNum.bl_or_amount;
                            } else {
                                giftRatio = regNum.bl_or_amount;
                            }
                        }
                    }
                    return {
                        num: item,
                        isHot: hot_bank_recharge_list.includes(item),
                        giftRatio, //活動贈送金額比例
                        giftAmount, //活動贈送金額
                    };
                } else {
                    return {
                        num: item,
                        isHot: hot_bank_recharge_list.includes(item),
                        giftRatio, //活動贈送金額比例
                        giftAmount, //活動贈送金額
                    };
                }


            });
        },
        dw() {
            return this.bankAll.currency_symbol;
        },
    },
    methods: {
        setting() {
            this.$refs.xwBankList.cardClick()
        },
        getWithdrawList() {
            this.withdrawList = [];
            this.wtBankList = [];
            this.wtAllBank = [];
            this.wtAllUsdt = [];
            this.wtAllWallet = [];
            this.wtAllPix = [];
            this.allWithdrawChannel = [];
            this.$store.commit("$vuexSetModuleLoadingState", true);
            bank_my_api({ bank_type: "all" }).then(res => {
                if (res.data.code == 1) {
                    this.wtBankList = res.data.data;

                    if (this.wtBankList.length > 0) {
                        this.selectWithdrawalChannels = this.wtBankList[0]
                    }

                    for (let i in this.wtBankList) {
                        if (!this.allWithdrawChannel[this.wtBankList[i].bank_type]) {
                            this.allWithdrawChannel[
                                this.wtBankList[i].bank_type
                            ] = new Array();
                        }
                        this.allWithdrawChannel[this.wtBankList[i].bank_type].push(
                            this.wtBankList[i]
                        );
                        // 獲取銀行卡
                        if (this.wtBankList[i].bank_type == 0) {
                            this.wtAllBank.push(this.wtBankList[i]);
                            this.wtAllBank.forEach(item => {
                                item.img = require("@/assets/images/bankcard.svg")
                            });
                            if (this.wtAllBank.length > 0) {
                                this.withdrawFlag = false;
                            } else {
                                this.withdrawFlag = true;
                            }
                        }
                        // 獲取USDT
                        if (this.wtBankList[i].bank_type == 1) {
                            this.wtAllUsdt.push(this.wtBankList[i]);
                            this.wtAllUsdt.forEach(item => {
                                if (item.bank_name.includes("TRC")) {
                                    item.img = require("@/assets/images/TRC20.png")
                                }
                            })
                        }
                        // 獲取電子錢包
                        if (this.wtBankList[i].bank_type == 2) {
                            this.wtAllWallet.push(this.wtBankList[i]);
                        }
                        // 獲取PIX
                        if (this.wtBankList[i].bank_type == 3) {
                            this.wtAllPix.push(this.wtBankList[i]);
                        }

                    }

                    if (this.withdrawList.length > 0) {
                        this.isDataShow = 1;
                        if (
                            this.currency_type == 0 &&
                            (this.withdrawList[0].keys == "bank" ||
                                this.withdrawList[0].keys == "pix" ||
                                this.withdrawList[0].keys == "wallet")
                        ) {
                            this.isUsdt = 0;
                        } else if (
                            this.currency_type == 1 &&
                            (this.withdrawList[0].keys == "bank" ||
                                this.withdrawList[0].keys == "pix" ||
                                this.withdrawList[0].keys == "wallet")
                        ) {
                            this.isUsdt = 1;
                        } else if (
                            this.currency_type == 0 &&
                            this.withdrawList[0].keys == "usdt"
                        ) {
                            this.isUsdt = 1;
                        } else if (
                            this.currency_type == 1 &&
                            this.withdrawList[0].keys == "usdt"
                        ) {
                            this.isUsdt = 0;
                        }
                    } else {
                        this.isDataShow = 0;
                    }
                }else{
                    this.errorTips(res.data.msg);
                }
                this.$store.commit("$vuexSetModuleLoadingState", false);
            });
        },
        calc(num, isRes, isNumFormat) {
            const numChange = isRes
                ? num / this.selectRechargeChannel.currencyApi.recharge_rate
                : num * this.selectRechargeChannel.currencyApi.recharge_rate;
            if (isNumFormat) {
                return numChange.toFixed(3);
            } else {
                return this.numFormat(numChange);
            }
        },
        selectChange(id) {
            this.selectRechargeChannel = this.allBankInfo.find(item => item.id === id)
        },
        rechargeGetAll() {
            this.allBank = [];
            this.allRechangeChannel = {};
            recharge_get_all_api().then(res => {
                if (res && res.data.code == 1) {
                    this.bankAll = res.data.data;
                    // 根據介面拼接線上線下資料
                    this.allBankInfo = res.data.data.online.concat(res.data.data.offline);
                    
                    console.log(this.allBankInfo, 'this.allBankInfo');
                    this.selectValue = this.allBankInfo[0].id
                    this.selectRechargeChannel = this.allBankInfo[0]
                    for (let i in this.allBankInfo) {
                        if (!this.allRechangeChannel[this.allBankInfo[i].type]) {
                            this.allRechangeChannel[this.allBankInfo[i].type] = new Array();
                        }
                        this.allRechangeChannel[this.allBankInfo[i].type].push(
                            this.allBankInfo[i]
                        );
                        // 獲取銀行卡
                        if (this.allBankInfo[i].type == 0) {
                            this.allBank.push(this.allBankInfo[i]);

                            this.allBank.forEach(item => {
                                item.img = require("@/assets/images/bankcard.svg")
                            });
                        }
                        // 獲取USDT
                        if (this.allBankInfo[i].type == 1) {
                            this.allUsdt.push(this.allBankInfo[i]);
                            this.allUsdt.forEach(item => {
                                if (item.pay_name) {
                                    if (item.pay_name.includes("TRC")) {
                                        item.img = require("@/assets/images/TRC20.png")
                                    }
                                }
                                if (item.bank_name) {
                                    if (item.bank_name.includes("ERC20")) {
                                        item.img = require("@/assets/images/erc20.svg")
                                    }
                                }
                            });
                        }
                        // 獲取電子錢包
                        if (this.allBankInfo[i].type == 2) {
                            this.allWallet.push(this.allBankInfo[i]);
                        }
                        // 獲取PIX
                        if (this.allBankInfo[i].type == 3) {
                            this.allPix.push(this.allBankInfo[i]);
                        }
                    }

                    this.dataLoading = true
                }else{
                    this.errorTips(res.data.msg);
                    this.dataLoading = true
                }

            }).catch(function (error) {
                console.log(error, 'error');
                // this.errorTips(error);
            });
        },
        tabNum(num) {
            this.depositNum = num;
        },
        onInput(e) {
            this.depositNum = this.clearIntegerNum(e)
        },
        uploadSuccess(e) {
            if (e.code == 1) {
                this.imageUrl = e.data.file;
            }
        },
        async submitDeposit() {
            if (this.$store.state.userInfo.user_verify_status != 2) {
                this.$xwDialog.open({
                    title: this.$t('訊息'),
                    content: this.$t('用戶未實名認證'),
                    btnTxt:this.$t('去認證'),
                    hasClose: false,
                    onConfirm: () => {
                        this.platUrl(serviceLink?.identificationLink)
                    }
                });
                return
            }
            if(!this.selectWithdrawalChannels.id){
                this.$xwDialog.open({
                    title: this.$t('訊息'),
                    content: this.$t('請選擇銀行卡'),
                    hasClose: false,
                    onConfirm: () => {
                    }
                });
                return
            }
            if (!this.depositNum) {
                this.$message({
                    showClose: true,
                    message: this.$t("請輸入充值金額"),
                    type: "error",
                });
                return;
            }

            // 判斷是否顯示二維碼資訊
            if (this.selectRechargeChannel.bank_number && this.isSecond == false) {
                this.isSecond = true
                return
            }

            // 判斷是否為usdt第二步是的話隱藏
            if (this.qrcodeData && this.isSecond) {
                this.hide()
                return
            }

            // 驗證憑證
            let pay_type = this.selectRechargeChannel.pay_type;
            if (pay_type == 0 || pay_type == 3 || pay_type == 4 || pay_type == 6) {
                if (!this.imageUrl) {
                    this.$message({
                        showClose: true,
                        message: this.$t("請上傳充值憑證"),
                        type: "error",
                    });
                    return;
                }
            }
            let form = {
                type: this.selectRechargeChannel.pay_type,
                bank_sk_id: this.selectRechargeChannel.id,
                apply_price: this.calc(this.depositNum, true, true),
                credentials: this.imageUrl, //充值憑證（圖片） type=0,3,4,6時必傳
                usdt_type: this.selectRechargeChannel.usdt_type,
                not_auto_action: this.isAction ? this.activeAction.id : (this.checked ? 1 : 0),
                bank_id:this.selectWithdrawalChannels.id
            };

            this.depositBtnLoading = true;
            let res = await recharge_apply_api(form);
            this.depositBtnLoading = false;
            if (res.data.code == 1) {
                if (res.data.data.is_qrcode == 1) {
                    this.qrcodeData = res.data.data;
                    this.isSecond = true
                } else if (res.data.data.handle_type == "navto") {
                    this.depositBtnLoading = true;
                    window.setTimeout(() => {
                        this.depositBtnLoading = false;
                    }, 3000);
                    window.location.assign(res.data.data.pay_url);
                } else {
                    this.$xwDialog.open({
                        title: this.$t('訊息'),
                        content: this.$t('提交成功，等待後臺稽核'),
                        hasClose: false,
                        onConfirm: () => {
                            this.isSecond = false
                            this.depositNum = ''
                        }
                    });
                }
            } else {
                this.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        onCopy: function () {
            this.successTips("Copy success");
        },
    },
    mounted() {
        this.rechargeGetAll()
        let { token, token_key } = this.$helper.get("tokenInfo");
        this.uploadHeader.usertoken = token;
        this.uploadHeader.usertokenkey = token_key;
        this.getWithdrawList()
    }
}
</script>

<style lang="less" scoped>
.main_platform {
    width: 768px;
    margin: 0 auto;
    padding-top: 6px;

    @media screen and (max-width:768px) {
        width: 100vw;
        padding-bottom: 38px;
        padding-top: 0;
    }
}

.main_inner {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    height: inherit;
    min-height: 320px;
    margin-bottom: 20px;

    @media screen and (max-width:768px) {
        border: none;
        padding: 0;
        height: auto;
        min-height: auto;
        padding: 8px 0 12px;
    }

}

.Myp_box_top {
    width: 100%;
    background-color: #efefef;
    display: block;
    height: 52px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    @media screen and (max-width:768px) {
        background-color: #fff;
        flex-direction: column;
        font-size: 14px;
        padding: 0;
        height: fit-content;

        span {
            width: fit-content;
            min-width: 84px;
        }
    }
}

.enter_box {
    color: #000;
    height: 52px;
    line-height: 52px;
    display: flex;
    align-items: center;
    gap: 12px;

    @media screen and (max-width:768px) {
        width: 100%;
        padding: 0 15px;
        border-bottom: 1px solid #dedede;
    }

}

.pay_mt_select {
    margin: 0px 18px 0px 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 35px !important;
    line-height: 35px;
    width: 180px;
    background-color: #fff;
    border: none;
    outline: none;

    @media screen and (max-width:768px) {
        width: 100%;
        border: none;
    }
}

.pay_mt_select1 ::v-deep .el-input__inner {
    border: 1px solid #a4c7eb !important;
}

.pay_mt_select1 ::v-deep .el-icon-arrow-up:before {
    content: '' !important;
    color: #a4c7eb;
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid currentColor; /* 使用当前字体颜色 */
    transform: rotate(0deg); /* 保持倒三角 */
    margin-left: 5px; /* 根据需要调整位置 */
    vertical-align: middle; /* 根据需要调整对齐 */
    transform-origin: center;
}

::v-deep .el-input__inner {
    height: 35px !important;
    border: none;
}

.form_button {
    display: block;
    height: 35px;
    width: 64px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    background-color: #398dc5;
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width:768px) {
        width: 94%;
        height: 42px !important;
        line-height: 36px;
        margin: 20px auto;

    }
}

.form_top {
    height: 35px;
    color: #333;
    line-height: 35px;
    padding: 9px 0;
    background-color: #fff;

    @media screen and (max-width:768px) {
        display: none;
    }
}

.formBox {
    margin-left: 14px;
    width: 220px;
    display: flex;
    align-items: center;
    gap: 16px;

}

.icon_inforMoney {
    color: #039612;
}

.num_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    padding: 8px 12px;
    gap: 12px;

    @media screen and (max-width:768px) {
        margin-top: 0;
    }
}

.num_item {
    position: relative;
    width: 99px;
    height: 50px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #dedede;
    cursor: pointer;

    &:hover {
        border: 1px solid #3a9b7c;
    }
}

.num_item.active {
    background-color: #3a9b7c;
    color: #fff;
}

.action-input {
    background-color: #3A495F;
    margin-bottom: 20px;
    height: 55px;
    text-align: center;
    border-radius: 10px;
    width: 100%;
    white-space: wrap;
    line-height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-right: 26px;
    position: relative;

}

.el-icon-caret-right {
    position: absolute;
    right: 10px;
}

.action-input-content {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 这里是超出几行省略 */
}

.checked_wrap {
    padding: 8px 12px;
}

::v-deep .el-checkbox__inner {
    border-radius: 50%;
    border-color: #3a07f2 !important;
    border: 2px solid #3a07f2;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #da0043 !important;
    border-color: #da0043 !important;
    border: 1px solid #da0043;
}

.checked {
    color: #3a07f2;
}

.checked-ac {
    color: #da0043;
}

.block_big {
    margin-bottom: 10px;
    background-color: #d5d5d5;
    width: 100%;
    display: flex;
    align-items: stretch;

    @media screen and (max-width:768px) {
        flex-direction: column;
        background-color: transparent;
    }
}

.data_L_Tips {
    width: 95px;
    min-height: 240px;
    background-color: #2b8568;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:768px) {
        background-color: #fff;
        width: 100%;
        height: 35px;
        min-height: auto;
        flex-direction: row;
        color: #000;
        border-bottom: 1px solid #ddd;
        gap: 8px;
    }
}

.icon_note {
    width: 53px;
    height: 59px;
    margin: 0 auto;
    background: url('../../../assets/images/xw/icon_group3.png') no-repeat -53px 0;

    @media screen and (max-width:768px) {
        background: url('../../../assets/images/xw/icon_precautions.svg') no-repeat;
        background-size: cover;
        width: 19px;
        height: 16px;
        margin: 0;
    }

}

.data_subtw {
    margin-left: 15px;
}

.precautions_tw {
    width: 638px;
    font-size: 14px;
    list-style-type: decimal;
    padding-left: 16px;
    margin: 0;
    color: #000;
    line-height: 25px;
    text-size-adjust: none;
    font-size: 14px;
    margin-bottom: 20px;

    @media screen and (max-width:768px) {
        width: 100%;
        font-size: 15px;
    }
}


.block_R_tips {
    width: 685px;
    display: flex;
    align-items: center;

    @media screen and (max-width:768px) {
        width: 100%;
        background-color: #fff;
        padding: 16px 10px 24px;
    }

}

.copy-icon4 {
    width: 22px;
    cursor: pointer;
}

.bank-title {
    min-width: 65px;
}

.bank-wapper {
    margin-bottom: 30px;

    @media screen and (max-width:768px) {
        padding: 0 16px;
    }
}

.upload_wrap {
    @media screen and (max-width:768px) {
        padding: 0 16px;
    }
}





.tip-item {
    color: #829cc5;
    margin-left: 19px;
    font-size: 13px;
}

.tip-title {
    margin-top: 19px;
    margin-bottom: 11px;
}

.tip {
    margin-bottom: 50px;
}

.bank-item {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    min-width: 65px;
    color: #000;
}

.bank-content {
    height: 42px;
    background-color: transparent;
    border: 1px solid #dedede;
    flex: 1;
    margin-left: 18px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px;
    flex-shrink: 0;
}

.bank-content span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    width: 0;
}


.vue-qr {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.code-wapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
    position: relative;
    align-items: center;
    @media screen and (max-width:768px) {
        padding: 0 16px;
    }
}

.code-input {
    width: 100%;
    background: #3a9b7c;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    padding: 4px 8px;
    position: relative;
    min-height: 4em;
    display: flex;
    align-items: center;
}

.copy-icon {
    width: 34px;
    height: 34px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    cursor: pointer;
    z-index: 999;
}

.copy-icon img {
    width: 100%;
}

.qr-wapper {
    width: 299px;
    height: 299px;
    flex-shrink: 0;
    margin-bottom: 30px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #34455d;
    width: 91px;
    height: 91px;
    line-height: 91px;
    text-align: center;
    border: 2px solid #34455d;
    border-radius: 4px;
}

.avatar {
    width: 91px;
    height: 91px;
    display: block;
    object-fit: cover;
}

.upload-title {
    margin-bottom: 12px;
    display: block;
}

.upload-wapper {
    padding: 14px 18px;
    border: 2px solid #2c3748;
    border-radius: 14px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    gap: 20px;
    color: red;
}

::v-deep .el-icon-loading {
    position: relative;
    left: 16px;
    top: 4px;
}

.suffix-icon {
    font-size: 12px;
    color: #00ff40;
    animation: suffixIco 1s infinite;
}

.copy-icon1 {
    width: 16px;
    height: 16px;
    margin-left: 14px;
    margin-bottom: 6px;
    cursor: pointer;
}

@keyframes suffixIco {
    0% {
        text-shadow: 0 0 0 #00ff40;
    }

    50% {
        text-shadow: 0 0 10px #00ff40;
    }

    100% {
        text-shadow: 0 0 0 #00ff40;
    }
}

/* .input ::v-deep .el-input__inner {
  padding-right: 100px;
} */
.checkbox {
    margin-bottom: 20px;
}

@media screen and (max-width: 555px) {
    .recharge-two-wapper {
        height: 100%;
        padding-left: 27px;
        padding-right: 27px;
        padding-bottom: calc(46px + 44px);
    }

    .btn-content {
        width: calc(100vw - 54px);
        font-weight: normal;
    }

    .el-icon-d-arrow-right {
        margin: 0 4px;
    }

    .input2 {
        width: 145px;
    }

    .title-wapper {
        margin-left: 16px;
    }

    .recharge-two-wapper {
        border-radius: 0;
        background: #0d131c;
    }

    .num-item {
        width: calc(25% - 7px);
        height: 46px;
        border-radius: 5px;
        line-height: 46px;
        font-size: 12px;
        margin-bottom: 8px;
    }

    .num-item:not(:nth-child(4n)) {
        margin-right: 8px;
    }

    .btn {
        padding: 12px 0;
    }

    .qr-wapper {
        width: 163px;
        height: 163px;
        flex-shrink: 0;
    }

    .code-input {
        font-size: 12px;
    }

    .copy-btn {
        background-color: #1bb83d;
        position: absolute;
        bottom: -5px;
        width: calc(100% - 103px - 9px);
        font-size: 12px;
        height: 29px;
        text-align: center;
        line-height: 29px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .copy-btn img {
        width: 1em;
        margin-right: 8px;
    }

    .copy-icon-xs {
        width: 14px;
        position: absolute;
        left: 13px;
    }
}

.btn_wrap {
    @media screen and (max-width:768px) {
        padding: 0 16px;
    }
}

.button_de {
    width: 100%;
}

.btn-icon {
    width: 1em;
    margin-left: 10px;
}

.hot-wapper {
  position: absolute;
  right: 0;
  top: -1px;
  background-color: #ed1d49;
  color: #fff;
  line-height: 1em;
  border-radius: 0 50vh 0 var(--vh);
  padding: 4px 10px;
  padding-left: 14px;
  font-size: 12px;
  transform: scale(0.8);
  transform-origin: top right;
}

.block_R_top_t {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #c1c1c1;
    color: #2b8568;
    font-size: 16px;
    padding: 0 15px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width:768px) {
        justify-content: center;
        background-color: #fff;

    }
}

.dep_TRW_notetw {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: calc(100% - 40px);

    @media screen and (max-width:768px) {
        flex-direction: column;
    }
}

.bank_wrap {
    width: 100%;
    padding: 8px 12px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;

    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-height: 184px;
        overflow: auto;
        gap: 4px;
    }


    @media screen and (max-width:768px) {
        width: 100%;
        padding: 8px 10px;
        background-color: #f0eff5;
    }

}

.set_btn {
    margin-left: 8px;
    padding: 4px 16px;
    height: 30px;
    margin-right: 1px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    background-image: linear-gradient(180deg, #309476, #2b8569);
}

.xwbank_card {
    width: 152px;
    height: 80px;
    background: url('../../../assets/images/bank-bg3.png') top center;
    background-size: 100% 100%;
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(50%);
    }

    @media screen and (max-width:768px) {
        width: 49%;
    }
}

.xwbank_card.active {
    filter: grayscale(0);
}
.item-title {
    display: flex;
    align-items: center;
    gap: 8px;
}

.bank-icon {
    widows: auto;
    height: 20px;
}

.add_card_btn{
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
    padding: 0;
    height: fit-content;
}

.tips_warn{
    color: #ed1d49;
    font-size: 12px;
    margin-top: -10px;
}
</style>