<template>
  <div class="set-cart-wapper">
    <div class="set-title-wapper">
      <svgIcon :icon="iconName" widthName="24" heightName="24"></svgIcon>
      <div class="set-title">{{ $t(title) }}</div>
      <svgIcon icon="icon-check-small" v-if="state" class="icon-check-small" widthName="24" heightName="24"></svgIcon>
      <svgIcon icon="icon-cross-small" v-else class="icon-cross-small" widthName="24" heightName="24"></svgIcon>
      <div class="state-txt" :style="{ color: !state ? '#DD1B30' : '#16C15E' }">{{ $t(state ? stateTxt : stateTxtNot) }}</div>
    </div>
    <div class="set-content-txt" v-if="content">
      {{ $t(content) }}
    </div>
   
   <el-button :loading="loading" @click="btnClick" v-if="!placeholder" :class="['button_fullwidth', !state ? 'button_red' : 'button_blue']">{{
      $t(state ? btnTxt : btnTxtNot)
    }}</el-button>
    <el-input style="color: #fff !important;" v-else :placeholder="$t(placeholder)" readonly :value="inputValue">
      <div class="suffix-right" slot="suffix" v-if="isInputBtn">
        <button type="button" class="button button_sm button_center button_only-icon"
          @click="inputBtnClick">

          <svgIcon icon="icon-edit" widthName="20" heightName="20"></svgIcon>
        </button>
      </div>
    </el-input>
  </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
export default {
  components: { svgIcon },
  props: {
    // 图标名字
    loading: {
      default: false,
    },
    iconName: {
      default: "",
    },

    // 状态设置或未设置
    state: {
      default: true,
    },

    // 状态文字显示
    stateTxt: {
      default: "已驗證",
    },

    // 状态文字显示
    stateTxtNot: {
      default: "未繫結",
    },

    // 按钮文字
    btnTxt: {
      default: "修改",
    },

    // 按钮文字
    btnTxtNot: {
      default: "啟用",
    },

    // 内容
    content: {
      default: "",
    },

    // input placeholder 
    placeholder: {
      default: "",
    },

    // 标题
    title: {
      default: "",
    },

    // input value
    inputValue: {
      default: ""
    },

    // 是否显示input里面的按钮
    isInputBtn: {
      default: true
    }
  },
  methods: {
    /**
     * 按钮点击事件
     * */
    btnClick() {
      this.$emit("btnClick", this.state)
    },

    /**
     * input里面的按钮点击事件
     * */
    inputBtnClick() {
      this.$emit("inputBtnClick", this.state)
    }
  }
};
</script>

<style lang="less" scoped>
.set-cart-wapper {
  background: #d5d5d5;
  border-radius: 5px;
  padding: 27px 23px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width:768px) {
    background: #fff;
  }
}

.set-title-wapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 16px;
  color: #3a9b7c;
}

.set-title {
  flex: 1;
  margin: 0 4px;
}

.icon-check-small {
  fill: #16C15E;
}

.state-txt {
  color: #ed1d49;
  font-weight: normal;
}

.set-f-txt {
  color: #727272;
  font-size: 12px;
  margin-bottom: 17px;
  flex: 1;
}

.icon-cross-small {
  fill: #ed1d49;
}

::v-deep .el-input__inner{
  background: linear-gradient(180deg, #3f9eff, #366ea7) !important;
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}

.set-content-txt{
  color: #000;
  margin-bottom: 8px;
}
</style>
