<template>
    <div class="withdrawa_page">
        <div class="block_big">
            <div class="data_L_TRW ">
                <div class="icon_data"></div>
                {{ $t('提交申請') }}
            </div>
            <div class="block_R">
                <div class="block_R_top_t">
                    <span class="block_title">{{ $t('請選擇您的銀行賬號') }}
                        <span v-if="wtBankList.length > 0" @click="setting" class="add_card_btn">{{ $t('新增卡片') }}</span>
                    </span>
                    <span v-if="$store.state.userInfo && $store.state.windowWidth > 768">{{ $t('餘額') }} {{
                        this.$helper.get("rule").currency.code }} {{
                            numFormat($store.state.userInfo.balance)
                        }}</span>
                </div>

                <div class="dep_TRW_notetw" v-loading="$store.state.moduleLoading"
                    element-loading-background="rgba(0, 0, 0, 0.2)">
                    <div class="bank_wrap">
                        <ul v-if="wtBankList.length > 0">
                            <li @click="selectWithdrawalChannels = item" v-for="item in wtBankList" :key="item.id"
                                class="xwbank_card" :class="selectWithdrawalChannels.id === item.id ? 'active' : ''">
                                <div class="item-title">
                                    <img src="@/assets/images/ban-icon.png" alt="" class="bank-icon">
                                    <span>{{ item.bank_name }}</span>
                                </div>
                                <div class="item-num">
                                    <span
                                        v-for="item, index in (item.bank_number.slice(0, 4) + '*******' + item.bank_number.slice(-4)) "
                                        :key="index">{{ item }}</span>
                                </div>
                            </li>
                        </ul>

                        <div v-else>
                            <span>{{ $t('未檢測到繫結卡片') }}</span>
                            <button @click="setting" type="button" class="set_btn"> {{ $t('去設定') }}</button>
                        </div>


                    </div>


                    <div class="netBank_box">
                        <div class="data_box_s">
                            <span>{{ $t('提款金額') }} :</span>
                            <input v-model="withdrawPrice" type="text" class="bank_code_5 " :placeholder="`${wtMinimum}~${wtHighest}`">
                        </div>
                        <div class="data_box_s">
                            <span>{{ $t('提款密碼') }} :</span>
                            <input type="new-password" autocomplete="off" class="bank_code_5 ">
                        </div>
                        <button :disabled="$store.state.bottonLoading" @click="submitWithdrawal" type="button"
                            class="data_Send ">{{ $t("確認取出") }}</button>
                    </div>
                </div>

            </div>
        </div>

        <div class="block_big">
            <div class=" data_L_Tips">
                <div class="icon_note"></div>
                {{ $t('注意事項') }}
            </div>
            <div class="block_R_tips">
                <div class="data_subtw">
                    <ul class="precautions_tw">
                        <li v-html="formattedText"></li>
                        <li>{{ $t("須達到近期存款金額與互轉點數") }} <span class="red_t">{{ $t('“相同的”') }}</span> {{
                            $t('有效投注量才可提出申請。') }}</li>
                        <li>{{ $t("如欲取消提款申請，請至會員中心→交易記錄→取消申請操作") }}</li>
                        <!-- <li v-if="bankAll.currency_code!=selectWithdrawalChannels.currency_code">{{ $t("今日匯率") }}：1{{ bankAll.currency_code }}={{ numFormat(selectWithdrawalChannels.withdrawal_rate) }}{{selectWithdrawalChannels.currency_code}}</li> -->
                    </ul>
                    <p class="red_t ps_t">{{ $t('PS. 如利用本平臺進行任何洗錢詐騙行為，本公司將保留權利終止會員服務及凍結其帳戶') }}</p>
                </div>
            </div>
        </div>
        <xwBankList ref="xwBankList" />
    </div>
</template>

<script>
import {
    bank_my_api,
    withdraw_limit_api,
    withdraw_apply_api
} from "@/api/user";
import xwBankList from "@/components/xwBankList.vue";
import { serviceLink } from "@/utils/serviceLink.js";

export default {
    components: {
        xwBankList
    },
    computed: {
        formattedText() {
            return `${this.$t('每')} <span class="red_t">${this.$t('日')}</span> ${this.$t('提款次數上限')} <span class="red_t">1</span> ${this.$t('次， 每')} <span class="red_t">${this.$t('日')}</span> ${this.$t('提款點數上限')} <span class="red_t">100000</span> ${this.$t('點。')}`;
        }
    },
    data() {
        return {
            withdrawList: '',
            wtBankList: '',
            wtAllBank: '',
            wtAllUsdt: '',
            wtAllWallet: '',
            wtAllPix: '',
            allWithdrawChannel: '',
            withdrawPrice: '',
            selectWithdrawalChannels: {},

            wtRnum: 0, //今日剩餘數量
            wtMinimum: 0, //最低提現金額
            wtHighest: 0, //最高提現金額
        }
    },
    mounted() {
        this.getWithdrawList()
        this.getWithdrawLimit()
    },
    methods: {
        setting() {
            this.$refs.xwBankList.cardClick()
        },
        getWithdrawList() {
            this.withdrawList = [];
            this.wtBankList = [];
            this.wtAllBank = [];
            this.wtAllUsdt = [];
            this.wtAllWallet = [];
            this.wtAllPix = [];
            this.allWithdrawChannel = [];
            this.$store.commit("$vuexSetModuleLoadingState", true);
            bank_my_api({ bank_type: "all" }).then(res => {
                if (res && res.data.code == 1) {
                    this.wtBankList = res.data.data;

                    if (this.wtBankList.length > 0) {
                        this.selectWithdrawalChannels = this.wtBankList[0]
                    }

                    for (let i in this.wtBankList) {
                        if (!this.allWithdrawChannel[this.wtBankList[i].bank_type]) {
                            this.allWithdrawChannel[
                                this.wtBankList[i].bank_type
                            ] = new Array();
                        }
                        this.allWithdrawChannel[this.wtBankList[i].bank_type].push(
                            this.wtBankList[i]
                        );
                        // 獲取銀行卡
                        if (this.wtBankList[i].bank_type == 0) {
                            this.wtAllBank.push(this.wtBankList[i]);
                            this.wtAllBank.forEach(item => {
                                item.img = require("@/assets/images/bankcard.svg")
                            });
                            if (this.wtAllBank.length > 0) {
                                this.withdrawFlag = false;
                            } else {
                                this.withdrawFlag = true;
                            }
                        }
                        // 獲取USDT
                        if (this.wtBankList[i].bank_type == 1) {
                            this.wtAllUsdt.push(this.wtBankList[i]);
                            this.wtAllUsdt.forEach(item => {
                                if (item.bank_name.includes("TRC")) {
                                    item.img = require("@/assets/images/TRC20.png")
                                }
                            })
                        }
                        // 獲取電子錢包
                        if (this.wtBankList[i].bank_type == 2) {
                            this.wtAllWallet.push(this.wtBankList[i]);
                        }
                        // 獲取PIX
                        if (this.wtBankList[i].bank_type == 3) {
                            this.wtAllPix.push(this.wtBankList[i]);
                        }

                    }

                    if (this.withdrawList.length > 0) {
                        this.isDataShow = 1;
                        if (
                            this.currency_type == 0 &&
                            (this.withdrawList[0].keys == "bank" ||
                                this.withdrawList[0].keys == "pix" ||
                                this.withdrawList[0].keys == "wallet")
                        ) {
                            this.isUsdt = 0;
                        } else if (
                            this.currency_type == 1 &&
                            (this.withdrawList[0].keys == "bank" ||
                                this.withdrawList[0].keys == "pix" ||
                                this.withdrawList[0].keys == "wallet")
                        ) {
                            this.isUsdt = 1;
                        } else if (
                            this.currency_type == 0 &&
                            this.withdrawList[0].keys == "usdt"
                        ) {
                            this.isUsdt = 1;
                        } else if (
                            this.currency_type == 1 &&
                            this.withdrawList[0].keys == "usdt"
                        ) {
                            this.isUsdt = 0;
                        }
                    } else {
                        this.isDataShow = 0;
                    }
                }
                this.$store.commit("$vuexSetModuleLoadingState", false);
            });
        },
        
        getWithdrawLimit() {
            withdraw_limit_api().then(res => {
                if (res && res.data.code == 1) {
                    // withdrawal_rules提現規則，1才有可提現金額
                    // user_withdrawal_amount：可提現金額
                    this.service_charge = res.data.data.service_charge;
                    this.usdt_charge = res.data.data.usdt_charge;
                    this.wtRnum = res.data.data.day_limit;
                    this.wtMinimum = res.data.data.min_amount;
                    this.wtHighest = res.data.data.max_amount;
                    this.withdrawal_rules = res.data.data.withdrawal_rules;
                    this.user_withdrawal_amount = res.data.data.user_withdrawal_amount;
                    this.ver_withdrawal_first = res.data.data.ver_withdrawal_first
                }
            });
        },

        async submitWithdrawal() {
            if (!this.withdrawPrice) {
                this.$message({
                    showClose: true,
                    message: this.$t("請輸入提現金額"),
                    type: 'error'
                });
                return
            }
            if (this.$store.state.userInfo.user_verify_status != 2) {
                this.$xwDialog.open({
                    title: this.$t('訊息'),
                    content: this.$t('用戶未實名認證'),
                    btnTxt:this.$t('去認證'),
                    hasClose: false,
                    onConfirm: () => {
                        this.platUrl(serviceLink?.identificationLink)
                        // this.$router.push({path:'/memberCenter/USERINFO'})
                    }
                });
                return
            }
            this.$store.commit("$vuexSetBottonLoadingState", true);
            let parm = {
                bank_id: this.selectWithdrawalChannels.id,
                w_price: this.withdrawPrice,
                // first_withdrawal_code: code,
                ispass: "0",
                // phone,
                // verify_code: this.verify_code
            };
            await withdraw_apply_api(parm).then(res => {
                if (res && res.data.code == 1) {
                    // this.verifyClose();
                    this.getWithdrawLimit();
                    this.reduceUserBalance(this.withdrawPrice);
                    this.withdrawPrice = '';
                    this.$xwDialog.open({
                        title: this.$t('訊息'),
                        content: this.$t(res.data.msg),
                        hasClose: false,
                        onConfirm: () => {
                            this.isSecond = false
                            this.depositNum = ''
                        }
                    });
                } else if (res && (res.data.code == 110 || res.data.code == 120 || res.data.code == 130)) {
                    this.verify_code && this.errorTips(res.data.msg);
                    // this.verifyShow("withdrawal_check", res.data.code);
                    this.$store.commit("$vuexSetBottonLoadingState", false);
                    return;
                } else {
                    // this.verifyClose();
                    this.errorTips(res.data.msg);
                }
                this.$store.commit("$vuexSetBottonLoadingState", false);
            });
        }
    }
}
</script>

<style lang="less" scoped>
.withdrawa_page {
    width: 780px;
    display: block;

    @media screen and (max-width:768px) {
        width: 100vw;
    }
}

.block_big {
    margin-bottom: 10px;
    background-color: #d5d5d5;
    width: 100%;
    display: flex;
    align-items: stretch;

    @media screen and (max-width:768px) {
        flex-direction: column;
        background-color: transparent;
    }
}

.data_L_TRW {
    width: 95px;
    height: 240px;
    background-color: #2b8568;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:768px) {
        display: none;
    }
}

.icon_data {
    width: 53px;
    height: 59px;
    margin: 0 auto;
    background: url('../../../assets/images/xw/icon_group3.png') no-repeat 0 0;

}

.block_R {
    width: 685px;

    @media screen and (max-width:768px) {
        width: 100%;
    }

}

.block_R_top_t {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #c1c1c1;
    color: #2b8568;
    font-size: 16px;
    padding: 0 15px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width:768px) {
        justify-content: center;
        background-color: #fff;

    }
}

.dep_TRW_notetw {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: calc(100% - 40px);

    @media screen and (max-width:768px) {
        flex-direction: column;
    }
}

.bank_wrap {
    width: 50%;
    padding: 8px 12px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;

    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-height: 184px;
        overflow: auto;
        gap: 4px;
    }


    @media screen and (max-width:768px) {
        width: 100%;
        padding: 8px 10px;
        background-color: #f0eff5;
    }

}

.set_btn {
    margin-left: 8px;
    padding: 4px 16px;
    height: 30px;
    margin-right: 1px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    background-image: linear-gradient(180deg, #309476, #2b8569);
}


.netBank_box {
    width: 215px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 10px;

    @media screen and (max-width:768px) {
        width: 100%;
        gap: 0;
    }
}

.data_box_s {
    color: #000;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 8px;

    @media screen and (max-width:768px) {
        width: 100%;
        border-bottom: 1px solid #ddd;
        height: 50px;
        padding: 0 4%;
        background-color: #fff;
        gap: 0;

        span {
            width: 95px;
        }
    }
}

.bank_code_5 {
    width: 140px;
    height: 30px;
    float: right;
    border: 1px;
    border-radius: 3px !important;
    box-sizing: border-box;
    box-shadow: none;
    outline-color: #9dbff6;

    @media screen and (max-width:768px) {
        width: 100%;
        height: 100%;
        outline: none
    }
}

.data_Send {
    width: 140px;
    height: 30px;
    margin-right: 1px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    // background-image: linear-gradient(180deg,#c3c2c2,#9d9d9d);
    background-image: linear-gradient(180deg, #309476, #2b8569);

    @media screen and (max-width:768px) {
        width: 92%;
        height: 40px;
        font-size: 16px;
        margin: 12px auto;
    }
}

.data_L_Tips {
    width: 95px;
    min-height: 240px;
    background-color: #2b8568;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:768px) {
        background-color: #fff;
        width: 100%;
        height: 35px;
        min-height: auto;
        flex-direction: row;
        color: #000;
        border-bottom: 1px solid #ddd;
        gap: 8px;
    }
}

.icon_note {
    width: 53px;
    height: 59px;
    margin: 0 auto;
    background: url('../../../assets/images/xw/icon_group3.png') no-repeat -53px 0;

    @media screen and (max-width:768px) {
        background: url('../../../assets/images/xw/icon_precautions.svg') no-repeat;
        background-size: cover;
        width: 19px;
        height: 16px;
        margin: 0;
    }

}

.data_subtw {
    margin-left: 15px;
}

.precautions_tw {
    width: 638px;
    font-size: 14px;
    list-style-type: decimal;
    padding-left: 16px;
    margin: 0;
    color: #000;
    line-height: 25px;
    text-size-adjust: none;
    font-size: 14px;
    margin-bottom: 8px;

    @media screen and (max-width:768px) {
        width: 100%;
        font-size: 15px;
    }
}

.block_R_tips {
    width: 685px;
    display: flex;
    align-items: center;

    @media screen and (max-width:768px) {
        width: 100%;
        background-color: #fff;
        padding: 16px 10px 24px;
    }

}

::-webkit-scrollbar {
    display: none;
}

.xwbank_card {
    width: 152px;
    height: 80px;
    background: url('../../../assets/images/bank-bg3.png') top center;
    background-size: 100% 100%;
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    filter: grayscale(100%);

    &:hover {
        filter: grayscale(50%);
    }

    @media screen and (max-width:768px) {
        width: 49%;
    }
}

.xwbank_card.active {
    filter: grayscale(0);
}

.item-title {
    display: flex;
    align-items: center;
    gap: 8px;
}

.bank-icon {
    widows: auto;
    height: 20px;
}

.block_title{
    display: flex;
    align-items: flex-end;
    gap: 12px;
    @media screen and (max-width:768px) {
        width: 100%;
        justify-content: space-between;
    }
}
.add_card_btn{
    cursor: pointer;
    font-size: 12px;
    text-decoration: underline;
    padding: 0;
    height: fit-content;
}
</style>