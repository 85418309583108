<template>
    <div class="MC_headerInner">
        <div class="MC_header">
            <div class="MC_headerR">
                <div class="filter-select" v-if="options.length > 0">
                    <el-select v-model="selectValue" @change="selectChange" :placeholder="$t('請選擇')">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="filter-date">
                    <el-date-picker format="MM-dd-yyyy" value-format="yyyy-MM-dd" v-model="dateVal" @change="dateChange"
                        type="daterange" range-separator="—" :start-placeholder="$t('開始日期')"
                        :end-placeholder="$t('結束日期')"></el-date-picker>
                </div>
            </div>
        </div>

        <div class="MC_tableOut">
            <table v-if="recodeType == 2" class=" " :style="$store.state.moduleLoading ? 'min-height: 200px' : ''"
                v-loading="$store.state.moduleLoading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <thead>
                    <tr>
                        <th>{{ $t('型別') }}</th>
                        <th>{{ $t('日期') }}</th>
                        <th>{{ $t('數量') }}</th>
                        <th>{{ $t('狀態') }}</th>
                        <th>{{ $t('訂單號') }}</th>
                        <th v-if="showType == 2">{{ $t('備註') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in re_record" :key="`re_record${index}`">
                        <td class="td__type">{{ $t('充值型別') }}</td>
                        <td class="td__date">
                            <span class="datetime">{{ item.add_time }}</span>
                        </td>
                        <td class="td__amount">
                            <span class="currency">
                                <div class="currency__balances-wrap">
                                    <div class="currency__balances">{{ item.apply_price }}</div>
                                </div>
                            </span>
                        </td>
                        <td class="td__status">
                            <div class="td__status-wrapper datetime">
                                <div v-if="item.status == -2" class="text-red">{{ $t('已取消') }}</div>
                                <div v-if="item.status == -1" class="text-red">{{ $t('延遲未支付') }}</div>
                                <div v-if="item.status == 1" class="text-yellow">{{ $t('待稽核') }}</div>
                                <div v-if="item.status == 2" class="text-red">{{ $t('未透過') }}</div>
                                <div v-if="item.status == 3 || item.status == 4" class="text-green">{{ $t('已透過') }}
                                </div>
                            </div>
                        </td>
                        <td class="td__information">
                            <div class="link">{{ item.order_sn }}</div>
                        </td>
                        <td class="td__information" v-if="showType == 2">
                            <div class="link">{{ item.w_remarks }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table v-else class="" :style="$store.state.moduleLoading ? 'min-height: 200px' : ''"
                v-loading="$store.state.moduleLoading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <thead>
                    <tr>
                        <th>{{ $t('型別') }}</th>
                        <th>{{ $t('子型別') }}</th>
                        <th>{{ $t('變更餘額') }}</th>
                        <th>{{ $t('變更後餘額') }}</th>
                        <th>{{ $t('時間') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in re_record" :key="`re_record${index}`">
                        <td class="td_name">
                            <span class="datetime">
                                {{ $t(item.type) }}
                            </span>
                        </td>
                        <td class="td_date">
                            <span class="datetime">{{ item.child_type ? item.child_type : '--' }}</span>
                        </td>
                        <td>
                            <span class="currency">
                                <div class="currency__balances-wrap">
                                    <div class="currency__balances">{{ numFormat(item.price, true) }}</div>
                                </div>
                            </span>
                        </td>
                        <td class="td_payout">
                            <span class="currency">
                                <div class="currency__balances-wrap">
                                    <div class="currency__balances">{{ numFormat(item.new_price, true) }}</div>
                                </div>
                            </span>
                        </td>
                        <td class="td_payout">
                            <span class="datetime">{{ item.add_time }}</span>
                        </td>

                    </tr>
                </tbody>
            </table>

            <div class="container-class" v-if="re_record.length > 0">
                <el-pagination background :small="isMobileFlag" :pager-count="5" layout="prev, pager, next"
                    :current-page.sync="record_page" :page-size="10" @current-change="changePageNum"
                    :total="re_listTotal"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { recharge_record_api, withdraw_record_api, game_order_api, game_balance_record_api } from "@/api/user";
export default {
    props: {
        recodeType: {
            type: Number,
            default: 2
        }
    },
    data() {
        return {
            options: [],
            showType: 1,
            selectValue: 1,
            start_time: "",
            end_time: "",
            dateVal: "",
            re_record: [],
            record_page: 1,
            limit: 5,
            re_listTotal: 0
        }
    },
    watch: {
        recodeType(v) {
            if (v === 2) {
                this.selectValue = 1
            } else {
                this.selectValue = 'all'
            }
            this.dateVal = "";
            this.start_time = "";
            this.end_time = "";
            this.moduleType()
        }
    },
    mounted() {
        if (this.recodeType == 2) {
            this.selectValue = 1
        } else {
            this.selectValue = 'all'
        }
        this.moduleType()
    },
    methods: {
        moduleType() {
            // this.record_page = 1
            this.re_record = [];
            if (this.recodeType == 2) {
                this.options = [
                    {
                        value: 1,
                        label: this.$t("充值型別"),
                    },
                    {
                        value: 2,
                        label: this.$t("提現型別"),
                    },
                ]
                this.getRechargeRecord();
            } else {
                this.getBalanceRecord()
            }
        },
        selectChange(e) {
            // this.dateVal = "";
            // this.start_time = "";
            // this.end_time = "";
            this.record_page = 1;
            if (this.recodeType == 2) {
                for (let i in this.options) {
                    if (this.options[i].value == e) {
                        this.showType = this.options[i].value;
                        this.selectValue = this.options[i].value;
                    }
                }
            }
            this.moduleType()
        },
        async getRechargeRecord() {
            this.$store.commit("$vuexSetModuleLoadingState", true);
            let data = {
                page: this.record_page,
                limit: 5,
                start_time: this.start_time,
                end_time: this.end_time
            };

            if (this.showType == 1) {
                await recharge_record_api(data).then(res => {
                    if (res && res.data.code == 1) {
                        this.re_record = res.data.data;
                        this.re_listTotal = res.data.total;
                    }
                    this.$store.commit("$vuexSetModuleLoadingState", false);
                });
            } else {
                await withdraw_record_api(data).then(res => {
                    if (res && res.data.code == 1) {
                        this.re_record = res.data.data;
                        this.re_listTotal = res.data.total;
                    }
                    this.$store.commit("$vuexSetModuleLoadingState", false);
                });
            }

        },

        async getBalanceRecord() {
            this.$store.commit("$vuexSetModuleLoadingState", true);
            let param = {};
            param = {
                page: this.record_page,
                limit: 5,
                start_time: this.start_time,
                end_time: this.end_time,
                type: this.selectValue,
            };
            await game_balance_record_api(param).then(res => {
                if (res && res.data.code == 1) {
                    console.log(res);
                    this.re_record = res.data.data;
                    this.re_listTotal = res.data.total;
                    if (res.data.type.length > 0) {
                        const option = []
                        res.data.type.forEach((ele, index) => {
                            option.push({ label: ele, value: index })
                        });
                        option.unshift({ label: this.$t("所有"), value: "all" })
                        this.options = option
                    }

                }
                this.$store.commit("$vuexSetModuleLoadingState", false);
            });
        },


        changePageNum(e) {
            this.record_page = e;
            this.re_record = [];
            this.moduleType();
        },

        dateChange(dateVal) {
            if (dateVal) {
                this.start_time = this.dateVal[0];
                this.end_time = this.dateVal[1];
            } else {
                this.start_time = "";
                this.end_time = "";
            }
            this.moduleType();
        },
    }
}

</script>

<style lang="less" scoped>
.MC_headerInner {
    width: 778px;
    margin: 0 auto;
    height: 458px;
    background-color: #efefef;
    border: 1px solid #d4d4d4;

    @media screen and (max-width:768px) {
        width: 100vw;
        border: none;
    }
}

.MC_header {
    background-color: #d4d4d4;
    height: 40px;
    padding: 0 10px;
    line-height: 38px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width:768px) {
        background-color: #fff;
    }
}

.MC_headerR {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

}

.filter-date {
    display: flex;
    align-items: center;
    max-width: 230px;
}

::v-deep .el-input__inner {
    height: 34px;
}

::v-deep .el-range-input {
    background: transparent !important;
    color: #666 !important;
}

::v-deep input[readonly="readonly"] {
    background-color: #fff;
}

::v-deep .el-select-dropdown {
    position: absolute;
    z-index: 1001;
    border: 1px solid #161f2c;
    border-radius: 4px;
    background: #fff !important;
    color: #ffffff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    box-sizing: border-box;
    margin: 5px 0;
}

::v-deep .el-select-dropdown__item.selected {
    color: #fff;
    pointer-events: none;
    background-image: linear-gradient(180deg, #309476, #2b8569) !important;
    background-color: transparent !important;
    border-radius: 8px;
}

.MC_tableOut {
    display: table;
    position: relative;
    width: 756px;
    height: 418px;
    margin: 10px;

    @media screen and (max-width:768px) {
        width: 100%;
        margin: 10px 0;
    }
}


.text-yellow {
    color: #ffb636 !important;
}

.filter-select {
    width: 107px !important;
}

table tbody td {
  position: relative;
  vertical-align: top;
  padding: 18px 8px;
  color: #000;
}
</style>