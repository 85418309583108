<template>
  <el-dialog :visible.sync="isShow" custom-class="custom-dialog">
    <div class="warp">
      <div class="title">{{ $t("請上傳身份證") }}</div>
      <div class="title-tip">{{ $t("上傳身份證正反面，圖片大小不超過3M") }}</div>
      <div class="content-warp">
        <div class="upload-list">
          <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :on-change="(e)=>change(e,'id_card_front')" :disabled="loadings.id_card_front || !isUpload"
            :on-success="(e)=>uploadSuccess(e,'id_card_front')" :headers="uploadHeader">
            <div class="card-warp" v-loading="loadings.id_card_front">
              <div class="card-content">
                <img :src="findData('id_card_front').value" class="upload-img" v-if="findData('id_card_front').value" key="upload-img">
                <img src="@/assets/images/camera.png" class="upload-icon" v-else key="upload-icon">
              </div>
            </div>
            <span class="upload-tip">{{ $t("上傳身份證正面") }}</span>
          </el-upload>
          <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :on-change="(e)=>change(e,'id_card_back')" :disabled="loadings.id_card_back || !isUpload"
            :on-success="(e)=>uploadSuccess(e,'id_card_back')" :headers="uploadHeader">
            <div class="card-warp" v-loading="loadings.id_card_back">
              <div class="card-content">
                <img :src="findData('id_card_back').value" class="upload-img" v-if="findData('id_card_back').value" key="upload-img">
                <img src="@/assets/images/camera.png" class="upload-icon" v-else key="upload-icon">
              </div>
            </div>
            <span class="upload-tip">{{ $t("上傳身份證反面") }}</span>
          </el-upload>
        </div>
        <div class="title title2">{{ $t("請上傳存摺") }}</div>
        <div class="title-tip">{{ $t("上傳存摺封面，圖片大小不超過3M") }}</div>
        <div class="upload-list">
          <el-upload class="avatar-uploader" :action="action" :show-file-list="false" :on-change="(e)=>change(e,'bankbook')" :disabled="loadings.bankbook || !isUpload"
            :on-success="(e)=>uploadSuccess(e,'bankbook')" :headers="uploadHeader">
            <div class="card-warp" v-loading="loadings.bankbook">
              <div class="card-content">
                <img :src="findData('bankbook').value" class="upload-img" v-if="findData('bankbook').value" key="upload-img">
                <img src="@/assets/images/camera.png" class="upload-icon" v-else key="upload-icon">
              </div>
            </div>
            <span class="upload-tip">{{ $t("存摺封面") }}</span>
          </el-upload>
        </div>
      </div>
      <div class="status" v-if="userInfo.user_verify_status == 2">
        <img src="@/assets/images/id-icon.png" alt="" class="id-icon">
        <span>{{ $t("已認證") }}</span>
      </div>
      <div class="status status-yellow"  v-if="userInfo.user_verify_status == 1">
        <img src="@/assets/images/id-icon-yellow.png" alt="" class="id-icon">
        <span>{{ $t("正在稽核中，請耐心等待") }}</span>
      </div>
      <div class="status status-red"  v-if="userInfo.user_verify_status == 3">
        <img src="@/assets/images/id-icon-red.png" alt="" class="id-icon">
        <span>{{ $t("稽核失敗，重新認證") }}</span>
      </div>
      <el-button class="button_jade button_fullwidth" @click="submit" v-if="isUpload" :loading="btnLoading">{{ $t("立即認證") }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {user_add_verify_api,user_verify_vonfig_api} from "@/api/user"
export default {
  data() {
    return {
      isShow: false,
      uploadHeader: { usertoken: "", usertokenkey: "" },
      action: process.env.VUE_APP_API_URL + '/upload/img',
      btnLoading:false,
      loadings:{
        id_card_front:false,
        id_card_back:false,
        bankbook:false,
      },
      data: [
        {
          name: "身份證正面",
          key: "id_card_front",//返回資料鍵
          type: "img",//資料型別
          value: ""//實際資料
        }, {
          name: "身份證反面",
          key: "id_card_back",
          type: "img",
          value: ""
        }, {
          name: "存摺封面",
          key: "bankbook",
          type: "img",
          value: ""
        }
      ],
    }
  },
  computed:{
    // 是否能夠上傳
    isUpload(){
      return this.userInfo.user_verify_status==0 || this.userInfo.user_verify_status==3
    },
    userInfo(){
      return this.$store.state.userInfo || {}
    }
  },
  methods: {
    async show(){
      let { token, token_key } = this.$helper.get("tokenInfo");
      this.uploadHeader.usertoken = token;
      this.uploadHeader.usertokenkey = token_key;
      await this.getData()
      this.isShow = true;
    },
    async getData(){
      const {data:res} = await user_verify_vonfig_api()
      console.log(res)
      if(res.code==1){
        this.data = res.data
      }
    },
    async submit(){
      for(let i=0;i<this.data.length ;i++){
        if(!this.data[i].value){
          const msgs = {
            id_card_front:"請上傳身份證正面",
            id_card_back:"請上傳身份證反面",
            bankbook:"請上傳存摺封面",
          }
          this.errorTips(this.$t(msgs[this.data[i].key]))
          return;
        }
      }
      this.btnLoading = true
      const {data:res} = await user_add_verify_api({content:JSON.stringify(this.data)})
      this.btnLoading = false
      if(res.code==1){
        this.$message({
          showClose: true,
          message: this.$t("提交成功，等待後臺稽核"),
          type: "success",
        });
        // 更新狀態
        this.$store.commit("$vuexUserInfo",{
          ...this.userInfo,
          user_verify_status:1
        })
        this.isShow = false
      }else{
        this.errorTips(res.msg)
      }
    },
    findData(key){
      return this.data.find(item=>item.key===key)
    },
    change(e,key){
      this.loadings[key] = (e.status!=='success')
      this.$forceUpdate();
    },
    uploadSuccess(e,key) {
      if (e.code == 1) {
        this.findData(key).value = e.data.file;
        this.$forceUpdate();
      }
    },
  }
}
</script>
  
  <style scoped lang="less">
  .warp {
    color: #FFF;
    margin-top: -26px;
    max-height: 80vh;
    overflow: auto;
  }
  
  .title {
    font-size: 17px;
    margin-bottom: 18px;
  
    @media (max-width:540px) {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
  
  .title-tip {
    font-size: 12px;
    color: #C4C4C4;
    margin-bottom: 28px;
  
    @media (max-width:540px) {
      margin-bottom: 15px;
    }
  }
  
  ::v-deep .el-upload {
    width: 100%;
  }
  
  .card-warp {
    width: 100%;
    height: 0;
    padding-bottom: 57%;
    background: #FFF;
    border-radius: 10px;
    background-image: url(~@/assets/images/card-bg.png);
    background-size: cover;
    position: relative;
    overflow: hidden;
    @media (max-width:540px) {
      border-radius: 6px;
    }
  }
  
  .card-content {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .upload-icon {
    width: 69px;
    height: 69px;
  
    @media (max-width:540px) {
      width: 40px;
      height: 40px;
    }
  }
  
  .upload-tip {
    color: #A4CEFF;
    font-size: 12px;
    margin-top: 8px;
    display: block;
  }
  
  .upload-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content-warp {
    background: #2b6452;
    border-radius: 18px;
    padding: 20px;
    margin-bottom: 20px;
  
    @media (max-width:540px) {
      border-radius: 10px;
      padding: 15px;
    }
  }
  
  .upload-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
  
    @media (max-width:540px) {
      gap: 10px;
    }
  }
  
  .title2 {
    margin-top: 30px;
  
    @media (max-width:540px) {
      margin-top: 20px;
    }
  }
  
  .status {
    display: flex;
    align-items: center;
    color: #1671F1;
  
    &.status-red {
      color: #D83232;
    }
    &.status-yellow{
      color: #C3B83F;
    }
  
    .id-icon {
      width: 27px;
      margin-right: 20px;
    }
  }
  .button_blue{
    margin-top: 28px;
  }
  </style>