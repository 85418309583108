<template>
    <div class="information_page">

        <div class="information_card_wrap">

            <div class="information_card_item">
                <settingCart :state="$store.state.userInfo.user_verify_status == 2" iconName="icon-shiming" title="實名認證"
                    :btnTxtNot="'去認證'"
                    :stateTxtNot="user_verify_status_text[$store.state.userInfo.user_verify_status]" btnTxt="認證成功"
                    :loading="realNameAuthenticationLoading" @btnClick="showRealNameAuthentication" content="身份認證解鎖更多功能"
                    class="setting-cart"></settingCart>
            </div>

            <div class="information_card_item" v-if="rule.user_google_status == 1">
                <settingCart :state="$store.state.userInfo && $store.state.userInfo.goodsle_bind == 1"
                    @btnClick="show2faModel" title="2FA" iconName="icon-google-auth" class="setting-cart"
                    stateTxt="2FA 已啟用" stateTxtNot="2FA 未啟用" btnTxt="設定" content="您的XW BET帳戶的第二個安全層。設定2FA 以保護您的帳戶（僅在註冊Google 或設定電子郵件和密碼時可用）"></settingCart>
            </div>

            <div class="information_card_item">
                <settingCart @btnClick="showChangepassword" :state="$store.state.isLoginStatu"
                    iconName="icon-locked-new" title="登入密碼" content="必須至少包含8個字元字母和字元的組合" class="setting-cart">
                </settingCart>
            </div>

            <div class="information_card_item">
                <settingCart :state="($store.state.userInfo && $store.state.userInfo.mail_bind_status) == 1"
                    iconName="icon-email-new" title="我的郵箱" content="設定電子郵件以隨時訪問平臺" placeholder="請繫結郵箱"
                    :stateTxtNot="($store.state.userInfo && $store.state.userInfo.mail_bind_status) == 2 ? '未驗證' : '未繫結'"
                    :inputValue="$store.state.userInfo && $store.state.userInfo.mail2"
                    @inputBtnClick="showMailModel($store.state.userInfo.mail_bind_status == 2)"
                    :isInputBtn="($store.state.userInfo && $store.state.userInfo.mail_bind_status) != 1"
                    class="setting-cart"></settingCart>
            </div>

            <div class="information_card_item">
                <settingCart :state="($store.state.userInfo && $store.state.userInfo.phone_bind_status) == 1"
                    iconName="icon-email-new" title="我的手機" content="繫結手機號碼以隨時訪問平臺" placeholder="請繫結手機"
                    :stateTxtNot="($store.state.userInfo && $store.state.userInfo.phone_bind_status) == 2 ? '未驗證' : '未繫結'"
                    @inputBtnClick="showPhoneModel($store.state.userInfo.phone_bind_status == 2)"
                    :isInputBtn="($store.state.userInfo && $store.state.userInfo.phone_bind_status) != 1"
                    :inputValue="$store.state.userInfo && $store.state.userInfo.phone ? `+${$store.state.userInfo.qh} ${$store.state.userInfo.phone}` : ''"
                    class="setting-cart"></settingCart>
            </div>

        </div>

        <changePassword ref="changePassword"></changePassword>
        <two-fa-act ref="fa2act"></two-fa-act>
        <two-fa-set ref="fa2set"></two-fa-set>
        <bindPhone ref="bindPhone"></bindPhone>
        <RealNameAuthentication ref="realNameAuthentication"></RealNameAuthentication>
    </div>
</template>

<script>
import svgIcon from "@/components/svg.vue";
import settingCart from "@/components/settingCart.vue";
import changePassword from "@/components/changePassword.vue";
import twoFaAct from "@/components/twoFaAct.vue";
import twoFaSet from "@/components/twoFaSet.vue";
import bindPhone from "@/components/bindPhone.vue";
import RealNameAuthentication from "@/components/RealNameAuthentication.vue";
import { serviceLink } from "@/utils/serviceLink.js";
export default {
    components: {
        svgIcon,
        settingCart,
        changePassword,
        twoFaAct,
        twoFaSet,
        bindPhone,
        RealNameAuthentication
    },
    data() {
        return {
            windowWidth: document.documentElement.clientWidth,
            checkList: [],
            userLoginEQ: [],
            rule: {},
            user_verify_status_text: ["未認證", "稽核中", "認證透過", "認證失敗"],
            realNameAuthenticationLoading: false,
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo || {}
        }
    },
    methods: {
        async showRealNameAuthentication() {
            window.open(serviceLink?.identificationLink)
            // if (this.userInfo.user_verify_status == 2) return
            // this.realNameAuthenticationLoading = true
            // await this.$refs.realNameAuthentication.show()
            // this.realNameAuthenticationLoading = false
        },
        /**
         * 顯示修改或設定2fa得彈窗
         * @param {boole} state 狀態 true是未設定顯示fa2set彈窗
         * */
        show2faModel(state) {
            if (!state) {
                this.$refs.fa2act.show()
            } else {
                this.$refs.fa2set.show()
            }
        },

        /**
         * 顯示修改按鈕彈窗
         * */
        showChangepassword() {
            this.$refs.changePassword.showPwdModal()
        },

        /**
        * 顯示郵箱彈窗
        * */
        showMailModel(isTest) {
            this.$refs.bindPhone.show(2, isTest)
        },

        /**
         * 顯示手機彈窗
         * */
        showPhoneModel(isTest) {
            this.$refs.bindPhone.show(1, isTest)
        },

        menuClick() {
            this.openedleft = !this.openedleft;
        },
        contestsClick() {
            this.openedcontests = !this.openedcontests;
        },
        contestsShow() {
            this.openedcontests = true;
        },
    },
    mounted() {
        this.rule = this.$helper.get("rule");
    },
    created() {
        this.isPhone();
    }
}
</script>

<style lang="less" scoped>
.information_page {
    width: 778px;
    margin: 0 auto;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    padding: 8px;

    @media screen and (max-width:768px) {
        width: 100vw;
        padding: 0;
        background-color: #efefef;
        border: none;
        padding-bottom: 20px;
    }
}

.information_card_wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
}

.information_card_item {
    width: calc(50% - 4px);

    @media screen and (max-width:768px) {
        width: 100%;
    }

}
</style>